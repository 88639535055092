import React, { useEffect, useState } from "react";
import * as io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [newTicket, setNewTicket] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const socketConnection = io(API_BASE_URL);

    setSocket(socketConnection);

    socketConnection.on("new_case", (data) => {
      console.log("New ticket opened", data.case);
      setNewTicket(data.case);
      toast.info(`New ticket opened: Ticket #${data.case.id}`, {
        onClick: () => navigate(`/ticket/${data.case.id}`),
      });
    });

    socketConnection.on("new_message", (data) => {
      console.log("New message", data.message);
      setNewMessage(data.message);
      toast.info(`New message in Ticket #${data.message.case_id}`, {
        onClick: () => navigate(`/ticket/${data.message.case_id}`),
      });
    });

    return () => {
      socketConnection.disconnect();
    };
  }, []);

  return <>{children}</>;
};

export default WebSocketProvider;
