import React from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const data = [
  { name: "Solved", value: 5 },
  { name: "Open", value: 3 },
  { name: "On-hold", value: 13 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

const ReportsPieChart = () => {
  return (
    <PieChart width={400} height={400}>
      <Pie data={data} cx="50%" cy="50%" labelLine={false} outerRadius={150} fill="#8884d8" dataKey="value">
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

export default ReportsPieChart;
