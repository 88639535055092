import React from "react";
import { Box, List, ListItem, ListItemText, Badge, Divider } from "@mui/material";
import "./Sidebar.css";

const Sidebar = ({ countsByStatus, currentFilter, onFilterChange }) => {
  const filters = [
    { label: "All tickets", value: "all" },
    { label: "New message", value: "open" },
    { label: "Replied", value: "pending" },
    { label: "Closed", value: "closed" },
  ];

  return (
    <Box width={240} bgcolor="grey.100" style={{ minHeight: "100vh" }} padding={2}>
      <List>
        {filters.map((filter) => (
          <ListItem
            button
            key={filter.value}
            onClick={() => onFilterChange(filter.value)}
            className="ticket-filter"
            style={{
              backgroundColor: currentFilter === filter.value ? "#e0e0e0" : "transparent",
              borderRadius: "4px",
              marginBottom: "8px",
            }}
          >
            <ListItemText primary={filter.label} />
            <Badge
              badgeContent={countsByStatus[filter.value] || 0}
              color="info"
              style={{ marginRight: "10px", background: "#903846" }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
};

export default Sidebar;
