import React, { useState, useEffect } from "react";
import { Box, TextField, Select, MenuItem, Button, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TicketSidebar = ({ ticket, agents }) => {
  const [subject, setSubject] = useState(ticket?.case?.subject || "");
  const [assignedAgent, setAssignedAgent] = useState(ticket?.case?.operator_id || "");
  const [priority, setPriority] = useState(ticket?.case?.priority || "");

  useEffect(() => {
    if (ticket) {
      setSubject(ticket.case.subject || "");
      setAssignedAgent(ticket.case.operator_id || "");
      setPriority(ticket.case.priority || "");
    }
  }, [ticket]);

  const handleSave = async () => {
    const payload = {
      ticket_id: ticket.case.id,
      agent_id: assignedAgent,
      priority: priority,
      subject: subject,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/ticket-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.error) {
        toast.error(data.description || data.message || "Error updating ticket details.");
      } else {
        toast.success("Ticket details updated successfully!");
      }
    } catch (error) {
      toast.error("An error occurred while saving the ticket.");
    }
  };

  const handleCloseTicket = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/close/${ticket.case.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await response.json();

      if (data.error) {
        toast.error(data.description || data.message || "Error closing the ticket.");
      } else {
        toast.success("Ticket closed successfully!");

        window.location.reload();
      }
    } catch (error) {
      toast.error("An error occurred while closing the ticket.");
    }
  };

  return (
    <Box width={240} style={{ minWidth: "240px" }} padding={2} bgcolor="grey.100" display="flex" flexDirection="column">
      <TextField
        label="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Select
        value={assignedAgent}
        onChange={(e) => setAssignedAgent(e.target.value)}
        fullWidth
        margin="normal"
        displayEmpty
      >
        <MenuItem value="" disabled>
          Assigned Agent
        </MenuItem>
        {agents.map((agent) => (
          <MenuItem key={agent.id} value={agent.id}>
            {agent.name}
          </MenuItem>
        ))}
      </Select>

      <Select
        value={priority}
        onChange={(e) => setPriority(e.target.value)}
        fullWidth
        style={{ marginTop: "10px" }}
        displayEmpty
      >
        <MenuItem value="" disabled>
          Priority
        </MenuItem>
        <MenuItem value="low">Low</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="high">High</MenuItem>
      </Select>

      {ticket && ticket.case.status !== "closed" ? (
        <Box display="flex" justifyContent="space-between" marginTop={4}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="outlined" color="error" onClick={handleCloseTicket}>
            Close ticket
          </Button>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" marginTop={4}>
          <Typography variant="body2" color="textSecondary">
            This ticket has been closed.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TicketSidebar;
