import React from "react";
import { AppBar, Toolbar, Typography, Box, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <AppBar position="static" className="app-bar">
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <a href="/dashboard">
            <div className="logo-block">
              <img src="/logo-white.png" alt="HelpMe" />
            </div>
          </a>
          <NavLink to="/dashboard" className="nav-link">
            <DashboardIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Tickets</Typography>
          </NavLink>

          <NavLink to="/contacts" className="nav-link">
            <PeopleIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Contacts</Typography>
          </NavLink>

          <NavLink to="/reports" className="nav-link">
            <BarChartIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Reports</Typography>
          </NavLink>
        </Box>

        <IconButton color="inherit">
          <NavLink to="/settings" className="nav-link" style={{ color: "inherit" }}>
            <SettingsIcon />
          </NavLink>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
