import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./pages/Dashboard";
import TicketDetail from "./pages/TicketDetail";
import Reports from "./pages/Reports";
import Contacts from "./pages/Contacts";
import WebSocketProvider from "./components/WebSocketProvider";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <Router>
      <WebSocketProvider>
        <ToastContainer position="top-right" autoClose={3000} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/contacts" element={<PrivateRoute element={<Contacts />} />} />
          <Route path="/reports" element={<PrivateRoute element={<Reports />} />} />
          <Route path="/ticket/:id" element={<PrivateRoute element={<TicketDetail />} />} />
          <Route path="/settings" element={<PrivateRoute element={<Settings />} />} />
        </Routes>
      </WebSocketProvider>
    </Router>
  );
}

export default App;
