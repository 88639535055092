import React from "react";
import { Box, Typography, Paper, LinearProgress } from "@mui/material";
import ReportsPieChart from "../components/PieChart";
import Navbar from "../components/Navbar";
import { SentimentVerySatisfied, SentimentNeutral, SentimentVeryDissatisfied } from "@mui/icons-material";
import "./Reports.css";

const Reports = () => {
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Navbar />
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ maxWidth: "1000px", margin: "0 auto" }}
          marginBottom={4}
        >
          <Box width="65%" padding={2}>
            <Typography variant="h6" className="block-title" gutterBottom>
              Customer Satisfaction
            </Typography>
            <Box display="flex" justifyContent="space-between">
              {/* Satisfaction Block 1 */}
              <Paper elevation={3} style={{ margin: "0 15px 0 0" }} className="satisfaction-block">
                <SentimentVerySatisfied fontSize="large" style={{ fill: "green" }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Positive
                  </Typography>
                  <Typography variant="h5" className="satisfaction-number">
                    82%
                  </Typography>
                </Box>
              </Paper>

              <Paper elevation={3} style={{ margin: "0 15px 0 0" }} className="satisfaction-block">
                <SentimentNeutral fontSize="large" style={{ fill: "orange" }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Neutral
                  </Typography>
                  <Typography variant="h5" className="satisfaction-number">
                    16%
                  </Typography>
                </Box>
              </Paper>

              <Paper elevation={3} style={{ margin: "0 15px 0 0" }} className="satisfaction-block">
                <SentimentVeryDissatisfied fontSize="large" style={{ fill: "red" }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Negative
                  </Typography>
                  <Typography variant="h5" className="satisfaction-number">
                    2%
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Box>

          <Box width="30%" padding={2}>
            <Typography variant="h6" className="block-title" gutterBottom>
              Ticket Priority
            </Typography>

            <Box className="ticket-priority-block">
              <Box display="flex" alignItems="center" marginBottom={2}>
                <Typography variant="body2" flexGrow={1}>
                  High
                </Typography>
                <LinearProgress variant="determinate" value={70} style={{ width: "60%", marginRight: 8 }} />
                <Typography variant="body2">3%</Typography>
              </Box>

              <Box display="flex" alignItems="center" marginBottom={2}>
                <Typography variant="body2" flexGrow={1}>
                  Medium
                </Typography>
                <LinearProgress variant="determinate" value={50} style={{ width: "60%", marginRight: 8 }} />
                <Typography variant="body2">17%</Typography>
              </Box>

              <Box display="flex" alignItems="center">
                <Typography variant="body2" flexGrow={1}>
                  Low
                </Typography>
                <LinearProgress variant="determinate" value={30} style={{ width: "60%", marginRight: 8 }} />
                <Typography variant="body2">80%</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box marginTop={4} style={{ maxWidth: "1000px", margin: "0 auto" }}>
          <Box display="flex" padding={2} justifyContent="space-between">
            {/* <Typography variant="h6" className="block-title" gutterBottom>
              Ticket Status
            </Typography> */}
            <ReportsPieChart />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Reports;
