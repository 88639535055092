import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDistanceToNow, set } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "./Dashboard.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Dashboard = ({ newTicket }) => {
  const [filter, setFilter] = useState("all");
  const [tickets, setTickets] = useState([]);
  const [agents, setAgents] = useState([]);
  const [countsByStatus, setCountsByStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);

      let url = `${API_BASE_URL}/api/v1/cases`;
      if (filter !== "all") {
        url += `?status=${filter}`;
      }

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        // logout expired session
        if (response.status === 403) {
          localStorage.removeItem("token");
          navigate("/");
          setError("Session expired. Please log in again.");
          return;
        }

        const data = await response.json();

        if (data.success) {
          setAgents(data.agents);
          setTickets(data.tickets);
          setCountsByStatus(data.statusCount);
        } else {
          setError("Failed to fetch tickets");
        }
      } catch (err) {
        setError("Error fetching tickets");
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [filter]);

  useEffect(() => {
    if (newTicket) {
      setTickets((prevTickets) => [newTicket, ...prevTickets]);
    }
  }, [newTicket, navigate]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const renderStatusChip = (status) => {
    switch (status) {
      case "open":
        return (
          <Chip
            label="New message"
            style={{ backgroundColor: "#4caf50", color: "#fff", width: "120px", textAlign: "center" }}
          />
        );
      case "new":
        return (
          <Chip
            label="New message"
            style={{ backgroundColor: "#4caf50", color: "#fff", width: "120px", textAlign: "center" }}
          />
        );
      case "pending":
        return (
          <Chip
            label="Replied"
            style={{ backgroundColor: "#ff9800", color: "#fff", width: "120px", textAlign: "center" }}
          />
        );
      case "replied":
        return (
          <Chip
            label="Replied"
            style={{ backgroundColor: "#ff9800", color: "#fff", width: "120px", textAlign: "center" }}
          />
        );
      case "closed":
        return (
          <Chip
            label="Closed"
            style={{ backgroundColor: "#f44336", color: "#fff", width: "120px", textAlign: "center" }}
          />
        );
      default:
        return (
          <Chip
            label="Unknown"
            style={{ backgroundColor: "#9e9e9e", color: "#fff", width: "120px", textAlign: "center" }}
          />
        );
    }
  };

  const renderUpdatedAt = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp * 1000), { addSuffix: true });
  };

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Navbar />
        <Box>
          {loading ? (
            <div style={{ display: "flex", marginTop: "100px", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress /> <Typography>Loading tickets...</Typography>
            </div>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Box display="flex">
              <Sidebar countsByStatus={countsByStatus} currentFilter={filter} onFilterChange={handleFilterChange} />
              <Box padding={2} flexGrow={1}>
                <TableContainer component={Paper}>
                  <Table className="tickets">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ticket ID</TableCell>
                        <TableCell>Subject</TableCell>
                        <TableCell>Last message</TableCell>
                        <TableCell>Agent</TableCell>
                        {/* <TableCell>Priority</TableCell> */}
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tickets.map((ticket) => (
                        <TableRow key={ticket.id}>
                          <TableCell>
                            <Link to={`/ticket/${ticket.id}`}>Ticket #{ticket.id}</Link>
                          </TableCell>
                          <TableCell>{ticket.subject}</TableCell>
                          <TableCell>{renderUpdatedAt(ticket.updated_at)}</TableCell>
                          <TableCell>
                            {!ticket.operator_id
                              ? "Unassigned"
                              : agents.find((agent) => agent.id === ticket.operator_id).name}
                          </TableCell>
                          {/* <TableCell>{ticket.priority}</TableCell> */}
                          <TableCell>{renderStatusChip(ticket.status)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
