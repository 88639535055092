import React, { useState } from "react";
import { Box, Button, TextField, Typography, Container } from "@mui/material";

const Login = () => {
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.token) {
        localStorage.setItem("token", data.token);
        window.location.href = "/dashboard";
      } else {
        setError(data.message || "Login failed");
      }
    } catch (error) {
      setError("Server error");
    }
  };

  return (
    <Container maxWidth="xs">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
        <img src="/logo.png" alt="Logo" style={{ marginBottom: "20px", width: "100px" }} />
        <form onSubmit={handleLogin} style={{ width: "100%" }}>
          <TextField margin="normal" fullWidth label="Email" type="email" name="email" variant="outlined" required />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            name="password"
            variant="outlined"
            required
          />
          {error && (
            <Typography color="error" variant="body2" style={{ margin: "10px 0", textAlign: "center" }}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: "15px", background: "#8d354c" }}
          >
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
