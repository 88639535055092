import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import Navbar from '../components/Navbar';
import axios from 'axios';
import { toast } from 'react-toastify';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Settings = () => {
  const [autoreply, setAutoreply] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAutoreply = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/settings/autoreply`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.data.success) {
          setAutoreply(response.data.value);
        } else {
          toast.error(response.data.message || 'Failed to fetch autoreply');
        }
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch autoreply');
      } finally {
        setLoading(false);
      }
    };
    fetchAutoreply();
  }, []);

  const handleSave = async () => {
    if (!autoreply.trim()) {
      toast.error('Autoreply text cannot be empty');
      return;
    }
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/settings/autoreply`,
        { value: autoreply },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      if (response.data.success) {
        toast.success('Autoreply updated successfully');
      } else {
        toast.error(response.data.message || 'Failed to update autoreply');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to update autoreply');
    }
  };

  if (loading) {
    return (
      <Box display="flex">
        <Box flexGrow={1}>
          <Navbar />
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Typography variant="h6">Loading...</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Navbar />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: '800px', margin: '0 auto', marginTop: '50px' }}
        >
          <Box width="100%">
            <Typography variant="h6" gutterBottom>
              Autoreply Settings
            </Typography>
            <TextField
              label="Autoreply Text"
              multiline
              rows={10}
              variant="outlined"
              fullWidth
              value={autoreply}
              onChange={(e) => setAutoreply(e.target.value)}
            />
            <Box marginTop={2}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
