import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress, TextField, Button, Paper } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../components/Navbar";
import TicketSidebar from "../components/TicketSidebar";
import "./TicketDetail.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TicketDetail = ({ newMessage }) => {
  const { id } = useParams();
  const [agents, setAgents] = useState([]);
  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [replyMessage, setReplyMessage] = useState("");

  const navigate = useNavigate();

  const fetchTicketDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/case/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      // logout expired session
      if (response.status === 403) {
        localStorage.removeItem("token");
        navigate("/");
        setError("Session expired. Please log in again.");
        return;
      }

      const data = await response.json();

      if (data.success) {
        setAgents(data.agents);
        setTicketData(data);
      } else {
        setError(data.description);
      }
    } catch (error) {
      setError("Error while retrieving case details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
  }, [id]);

  useEffect(() => {
    if (newMessage && newMessage.case_id === Number(id)) {
      fetchTicketDetails();
    }
  }, [newMessage, id]);

  const handleReply = async () => {
    if (!replyMessage.trim()) {
      toast.error("Please type a reply message.");
      return;
    }

    const payload = {
      response: replyMessage,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/reply/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.error) {
        toast.error(data.description || "Error sending reply.");
      } else {
        toast.success("Reply sent successfully!");

        const updatedResponse = await fetch(`${API_BASE_URL}/api/v1/case/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const updatedData = await updatedResponse.json();
        setTicketData(updatedData);

        setReplyMessage("");
      }
    } catch (error) {
      toast.error("Error while sending the reply.");
    }
  };

  return (
    <Box>
      <Navbar />
      <Box display="flex">
        <TicketSidebar ticket={ticketData} agents={agents} />

        <Box padding={2} flexGrow={1}>
          {loading ? (
            <>
              <CircularProgress /> <Typography>Loading ticket details...</Typography>
            </>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Box>
              <Paper elevation={3} className="chat-container">
                {ticketData.messages.map((msg) => (
                  <Box
                    key={msg.id}
                    className={`message ${
                      msg.from === "customer"
                        ? "customer-message"
                        : msg.from === "virtualagent"
                        ? "bot-message"
                        : "support-message"
                    }`}
                  >
                    <Typography variant="subtitle2" className="message-sender">
                      {msg.from === "customer" ? "Customer" : msg.from === "virtualagent" ? "Virtual Agent" : "Support"}
                    </Typography>
                    <Typography variant="caption" className="message-time">
                      {new Date(msg.created_at * 1000).toLocaleString()}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="message-body"
                      dangerouslySetInnerHTML={{ __html: msg.message }}
                    />
                  </Box>
                ))}
              </Paper>

              {ticketData && ticketData.case.status !== "closed" ? (
                <Box marginTop={2}>
                  <TextField
                    label="Type your reply"
                    multiline
                    rows={4}
                    fullWidth
                    value={replyMessage}
                    onChange={(e) => setReplyMessage(e.target.value)}
                  />
                  <Box marginTop={2} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleReply}>
                      Reply
                    </Button>
                  </Box>
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TicketDetail;
