import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import "./Contacts.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Contacts = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/customers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        // logout expired session
        if (response.status === 403) {
          localStorage.removeItem("token");
          navigate("/");
          setError("Session expired. Please log in again.");
          return;
        }
        const data = await response.json();
        if (data.success) {
          setCustomers(data.customers);
        } else {
          setError("Failed to fetch customers");
        }
      } catch (err) {
        setError("Error fetching customers");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  return (
    <Box>
      <Navbar />

      <Box display="flex" justifyContent="center" padding={4}>
        <Box width="100%" maxWidth="1000px">
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table className="contacts">
                <TableHead>
                  <TableRow>
                    <TableCell>Chat ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers
                    .filter((customer) => customer.chat_user_id)
                    .map((customer) => (
                      <TableRow key={customer.id}>
                        <TableCell>
                          {/* <Link to={`/contact/${customer.id}`}>{customer.chat_user_id}</Link> */}
                          {customer.chat_user_id}
                        </TableCell>
                        <TableCell>{customer.name}</TableCell>
                        <TableCell>{customer.msisdn}</TableCell>
                        <TableCell>{customer.email}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Contacts;
